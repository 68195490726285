import React, { createContext, useContext, useReducer } from "react";
import axios from "../utils/axios";
import { failure, success } from "../utils/toast";
import { getHeaders } from "../utils";
// import { PAGE_LIMIT } from "../utils/constants";

// Initial state
const initialState = {
  loading: false,
  data: [],
  pagination: null,
  errors: {},
  addService: {
    loading: false,
    errors: {},
  },
  oneService: {
    loading: false,
    data: [],
    errors: "",
  },
};

// Action types for authentication
const actionTypes = {
  ADD_SERVICE_LOADING: "ADD_SERVICE_LOADING",
  ADD_SERVICE_FAILURE: "ADD_SERVICE_FAILURE",
  SERVICES_LOADING: "SERVICES_LOADING",
  SERVICES_SUCCESS: "SERVICES_SUCCESS",
  SERVICES_FAILURE: "SERVICES_FAILURE",
  ONE_SERVICE_LOADING: "ONE_SERVICE_LOADING",
  ONE_SERVICE_SUCCESS: "ONE_SERVICE_SUCCESS",
  ONE_SERVICE_FAILURE: "ONE_SERVICE_FAILURE",
};

// Reducer function to handle authentication actions
const petServiceReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_SERVICE_LOADING:
      return {
        ...state,
        addService: {
          loading: action.payload,
        },
      };
    case actionTypes.ADD_SERVICE_FAILURE:
      return {
        ...state,
        addService: {
          loading: false,
          data: {},
          errors: action.payload,
        },
      };
    case actionTypes.SERVICES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        pagination: action.payload.pagination,
        errors: "",
      };
    case actionTypes.SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        pagination: null,
        errors: action.payload,
      };
    case actionTypes.ONE_SERVICE_LOADING:
      return {
        ...state,
        oneService: { loading: action.payload },
      };
    case actionTypes.ONE_SERVICE_SUCCESS:
      return {
        ...state,
        oneService: { loading: false, data: action.payload, errors: "" },
      };
    case actionTypes.ONE_SERVICE_FAILURE:
      return {
        ...state,
        oneService: { loading: false, errors: action.payload },
      };
    default:
      return state;
  }
};

// Create context
export const PetServiceContext = createContext();

// Create context provider
export const PetServiceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(petServiceReducer, initialState);

  // const fetchServices = (data) => {
  //   dispatch({
  //     type: actionTypes.SERVICES_LOADING,
  //     payload: true,
  //   });
  //   axios
  //     .post("/article/all/", data, getHeaders())
  //     .then((res) => {
  //       dispatch({
  //         type: actionTypes.SERVICES_SUCCESS,
  //         payload: res.data,
  //       });
  //     })
  //     .catch((err) => {
  //       failure();
  //       dispatch({
  //         type: actionTypes.SERVICES_FAILURE,
  //         payload: err?.response?.data?.message
  //           ? err.response.data.message
  //           : "",
  //       });
  //     });
  // };

  // const fetchoneService = (id) => {
  //   dispatch({
  //     type: actionTypes.ONE_SERVICE_LOADING,
  //     payload: true,
  //   });
  //   axios
  //     .get(`/article/${id}/`)
  //     .then((res) => {
  //       dispatch({
  //         type: actionTypes.ONE_SERVICE_SUCCESS,
  //         payload: res?.data?.data,
  //       });
  //     })
  //     .catch((err) => {
  //       failure();
  //       dispatch({
  //         type: actionTypes.ONE_SERVICE_FAILURE,
  //         payload: err?.response?.data?.message
  //           ? err.response.data.message
  //           : "",
  //       });
  //     });
  // };

  const addPetService = (newService, cb) => {
    dispatch({
      type: actionTypes.ADD_SERVICE_LOADING,
      payload: true,
    });
    axios
      .post("/pet-service/add/", newService, getHeaders())
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_SERVICE_LOADING,
          payload: false,
        });
        success(res.data.message);
        cb();
      })
      .catch((err) => {
        failure(err?.response?.data?.message);
        dispatch({
          type: actionTypes.ADD_SERVICE_FAILURE,
          payload: err?.response?.data?.errors ? err.response.data.errors : {},
        });
      });
  };

  // Value object to be provided to consuming components
  const contextValue = {
    state,
    addPetService,
  };

  return (
    <PetServiceContext.Provider value={contextValue}>
      {children}
    </PetServiceContext.Provider>
  );
};

export const usePetService = () => useContext(PetServiceContext);
