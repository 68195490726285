import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import * as URLS from "./urls";
import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";
import FullScreenLoading from "../components/Loader/FullScreenLoading";

const AdminRegister = React.lazy(() => import("../pages/Admin/Auth/Register"));
const AdminLogin = React.lazy(() => import("../pages/Admin/Auth/Login"));
const AdminDashboard = React.lazy(() =>
  import("../pages/Admin/Dashboard/Dashboard")
);

const Register = React.lazy(() => import("../pages/Auth/Register"));
const Login = React.lazy(() => import("../pages/Auth/Login"));
const Home = React.lazy(() => import("../pages/Home/Home"));
const AddArticle = React.lazy(() => import("../pages/AddArticle/AddArticle"));
const EditArticle = React.lazy(() =>
  import("../pages/EditArticle/EditArticle")
);
const MyArticles = React.lazy(() =>
  import("../pages/ManageArticles/ManageArticles")
);
const ArticlePage = React.lazy(() =>
  import("../pages/ArticlePage/ArticlePage")
);
const Entertainment = React.lazy(() =>
  import("../pages/Entertainment/Entertainment")
);
const SearchServices = React.lazy(() =>
  import("../pages/SearchServices/SearchServices")
);
const AddServices = React.lazy(() =>
  import("../pages/AddServices/AddServices")
);
const SearchJobs = React.lazy(() => import("../pages/SearchJobs/SearchJobs"));
const AddJobs = React.lazy(() => import("../pages/AddJobs/AddJobs"));

const router = createBrowserRouter([
  {
    path: URLS.ADMIN_REGISTER,
    element: (
      <PrivateRoute admin>
        <AdminRegister />
      </PrivateRoute>
    ),
  },
  {
    path: URLS.ADMIN_LOGIN,
    element: (
      <GuestRoute disableNav>
        <AdminLogin />
      </GuestRoute>
    ),
  },
  {
    path: URLS.ADMIN_DASHBOARD,
    element: (
      <PrivateRoute admin>
        <AdminDashboard />
      </PrivateRoute>
    ),
  },
  {
    path: URLS.REGISTER,
    element: (
      <GuestRoute disableNav>
        <Register />
      </GuestRoute>
    ),
  },
  {
    path: URLS.LOGIN,
    element: (
      <GuestRoute disableNav>
        <Login />
      </GuestRoute>
    ),
  },
  {
    path: URLS.HOME,
    element: (
      <GuestRoute>
        <Home />
      </GuestRoute>
    ),
  },
  {
    path: URLS.ADD_ARTICLE,
    element: (
      <PrivateRoute>
        <AddArticle />
      </PrivateRoute>
    ),
  },
  {
    path: URLS.EDIT_ARTICLE,
    element: (
      <PrivateRoute>
        <EditArticle />
      </PrivateRoute>
    ),
  },
  {
    path: URLS.MANAGE_ARTICLES,
    element: (
      <PrivateRoute>
        <MyArticles />
      </PrivateRoute>
    ),
  },
  {
    path: URLS.ARTICLE_PAGE,
    element: (
      <GuestRoute>
        <ArticlePage />
      </GuestRoute>
    ),
  },
  {
    path: URLS.ENTERTAINMENT,
    element: (
      <GuestRoute>
        <Entertainment />
      </GuestRoute>
    ),
  },
  {
    path: URLS.SEARCH_SERVICES,
    element: (
      <GuestRoute>
        <SearchServices />
      </GuestRoute>
    ),
  },
  {
    path: URLS.ADD_SERVICES,
    element: (
      <PrivateRoute>
        <AddServices />
      </PrivateRoute>
    ),
  },
  {
    path: URLS.SEARCH_JOBS,
    element: (
      <GuestRoute>
        <SearchJobs />
      </GuestRoute>
    ),
  },
  {
    path: URLS.ADD_JOBS,
    element: (
      <PrivateRoute>
        <AddJobs />
      </PrivateRoute>
    ),
  },
]);

export default function Routes() {
  return (
    <Suspense fallback={<FullScreenLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
