import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { AuthProvider } from "./context/AuthProvider";
import { ArticleProvider } from "./context/ArticleContext";
import { PetServiceProvider } from "./context/PetServicesContext";
import { JobProvider } from "./context/JobsContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <ArticleProvider>
        <PetServiceProvider>
          <JobProvider>
            <App />
          </JobProvider>
        </PetServiceProvider>
      </ArticleProvider>
    </AuthProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);
