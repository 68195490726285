export const ADMIN_REGISTER =
  "/admin/protected/register/5e77357cf473133240f9e7ac6fa7d2ee";
export const ADMIN_LOGIN = "/admin/login";
export const ADMIN_DASHBOARD = "/admin/dashboard";

export const REGISTER = "/register";
export const LOGIN = "/login";
export const HOME = "/";
export const ADD_ARTICLE = "/add-article";
export const EDIT_ARTICLE = "/edit-article";
export const ARTICLE_PAGE = "/article/:id";
export const ENTERTAINMENT = "/entertainment";
export const FORGOT_PASSWORD = "/forgot-password";
export const SEARCH_SERVICES = "/search-services";
export const ADD_SERVICES = "/add-services";
export const SEARCH_JOBS = "/search-jobs";
export const ADD_JOBS = "/add-jobs";
export const MANAGE_ARTICLES = "/manage-articles";
