export const getBase64Promise = (file) =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve({
        name: file?.name,
        data: reader?.result,
        size: file?.size,
      });
    };
    reader.onerror = function (error) {
      reject("Error: ", error);
    };
  });

export const htmlToText = (html) => {
  const temp = document.createElement("div");
  temp.innerHTML = html;
  return temp.textContent;
};

export function getHeaders(otherHeaders) {
  const token = localStorage.getItem(process.env.REACT_APP_USERTOKEN);

  return {
    headers: {
      Authorization: token,
      ...otherHeaders,
    },
  };
}

export const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN);
};

export const isUserAdmin = () => {
  return localStorage.getItem(process.env.REACT_APP_ADMIN_ROLE) === "true"
    ? true
    : false;
};
