import React, { Suspense, useEffect } from "react";
import LayoutContainer from "../components/Layout/LayoutContainer";
import FullScreenLoading from "../components/Loader/FullScreenLoading";
import { useNavigate } from "react-router-dom";
import { HOME } from "./urls";
import { getToken } from "../utils";

export default function GuestRoute({ children, disableNav }) {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();
    if (!!token && disableNav) {
      navigate(HOME);
    }
  }, [disableNav, navigate]);

  return disableNav ? (
    <>
      <Suspense fallback={<FullScreenLoading />}>{children}</Suspense>
    </>
  ) : (
    <LayoutContainer>
      <Suspense fallback={<FullScreenLoading />}>{children}</Suspense>
    </LayoutContainer>
  );
}
