import React, { createContext, useContext, useEffect, useReducer } from "react";
import axios from "../utils/axios";
import { failure, success } from "../utils/toast";
import { getHeaders } from "../utils";
import { PAGE_LIMIT } from "../utils/constants";

// Initial state
const initialState = {
  loading: false,
  data: [],
  pagination: null,
  errors: {},
  addArticle: {
    loading: false,
    errors: {},
  },
  oneArticle: {
    loading: false,
    data: [],
    errors: "",
  },
  mostViewedAll: {
    loading: false,
    data: [],
    errors: "",
  },
  mostViewedToday: {
    loading: false,
    data: [],
    errors: "",
  },
  mostViewedWeek: {
    loading: false,
    data: [],
    errors: "",
  },
  mostViewedMonth: {
    loading: false,
    data: [],
    errors: "",
  },
};

// Action types for authentication
const actionTypes = {
  ADD_ARTICLE_LOADING: "ADD_ARTICLE_LOADING",
  ADD_ARTICLE_FAILURE: "ADD_ARTICLE_FAILURE",
  ARTICLES_LOADING: "ARTICLES_LOADING",
  ARTICLES_SUCCESS: "ARTICLES_SUCCESS",
  ARTICLES_FAILURE: "ARTICLES_FAILURE",
  ONE_ARTICLE_LOADING: "ONE_ARTICLE_LOADING",
  ONE_ARTICLE_SUCCESS: "ONE_ARTICLE_SUCCESS",
  ONE_ARTICLE_FAILURE: "ONE_ARTICLE_FAILURE",
  MOST_VIEWED_ARTICLES_LOADING: "MOST_VIEWED_ARTICLES_LOADING",
  MOST_VIEWED_ARTICLES_SUCCESS: "MOST_VIEWED_ARTICLES_SUCCESS",
  MOST_VIEWED_ARTICLES_FAILURE: "MOST_VIEWED_ARTICLES_FAILURE",
  MOST_VIEWED_ARTICLES_TODAY_LOADING: "MOST_VIEWED_ARTICLES_TODAY_LOADING",
  MOST_VIEWED_ARTICLES_TODAY_SUCCESS: "MOST_VIEWED_ARTICLES_TODAY_SUCCESS",
  MOST_VIEWED_ARTICLES_TODAY_FAILURE: "MOST_VIEWED_ARTICLES_TODAY_FAILURE",
  MOST_VIEWED_ARTICLES_WEEK_LOADING: "MOST_VIEWED_ARTICLES_WEEK_LOADING",
  MOST_VIEWED_ARTICLES_WEEK_SUCCESS: "MOST_VIEWED_ARTICLES_WEEK_SUCCESS",
  MOST_VIEWED_ARTICLES_WEEK_FAILURE: "MOST_VIEWED_ARTICLES_WEEK_FAILURE",
  MOST_VIEWED_ARTICLES_MONTH_LOADING: "MOST_VIEWED_ARTICLES_MONTH_LOADING",
  MOST_VIEWED_ARTICLES_MONTH_SUCCESS: "MOST_VIEWED_ARTICLES_MONTH_SUCCESS",
  MOST_VIEWED_ARTICLES_MONTH_FAILURE: "MOST_VIEWED_ARTICLES_MONTH_FAILURE",
};

// Reducer function to handle authentication actions
const articleReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_ARTICLE_LOADING:
      return {
        ...state,
        addArticle: {
          loading: action.payload,
        },
      };
    case actionTypes.ADD_ARTICLE_FAILURE:
      return {
        ...state,
        addArticle: {
          loading: false,
          data: {},
          errors: action.payload,
        },
      };

    case actionTypes.ARTICLES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        pagination: action.payload.pagination,
        errors: "",
      };
    case actionTypes.ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
        pagination: null,
        errors: action.payload,
      };
    case actionTypes.MOST_VIEWED_ARTICLES_LOADING:
      return {
        ...state,
        mostViewedAll: {
          loading: action.payload,
        },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_SUCCESS:
      return {
        ...state,
        mostViewedAll: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_FAILURE:
      return {
        ...state,
        mostViewedAll: {
          loading: false,
          errors: action.payload,
        },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_TODAY_LOADING:
      return {
        ...state,
        mostViewedToday: {
          loading: action.payload,
        },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_TODAY_SUCCESS:
      return {
        ...state,
        mostViewedToday: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_TODAY_FAILURE:
      return {
        ...state,
        mostViewedToday: {
          loading: false,
          errors: action.payload,
        },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_WEEK_LOADING:
      return {
        ...state,
        mostViewedWeek: {
          loading: action.payload,
        },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_WEEK_SUCCESS:
      return {
        ...state,
        mostViewedWeek: { loading: false, data: action.payload, errors: {} },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_WEEK_FAILURE:
      return {
        ...state,
        mostViewedWeek: { loading: false, errors: action.payload },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_MONTH_LOADING:
      return {
        ...state,
        mostViewedMonth: {
          loading: action.payload,
        },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_MONTH_SUCCESS:
      return {
        ...state,
        mostViewedMonth: { loading: false, data: action.payload, errors: {} },
      };
    case actionTypes.MOST_VIEWED_ARTICLES_MONTH_FAILURE:
      return {
        ...state,
        mostViewedMonth: { loading: false, errors: action.payload },
      };
    case actionTypes.ONE_ARTICLE_LOADING:
      return {
        ...state,
        oneArticle: { loading: action.payload },
      };
    case actionTypes.ONE_ARTICLE_SUCCESS:
      return {
        ...state,
        oneArticle: { loading: false, data: action.payload, errors: "" },
      };
    case actionTypes.ONE_ARTICLE_FAILURE:
      return {
        ...state,
        oneArticle: { loading: false, errors: action.payload },
      };
    default:
      return state;
  }
};

// Create context
export const ArticleContext = createContext();

// Create context provider
export const ArticleProvider = ({ children }) => {
  const [state, dispatch] = useReducer(articleReducer, initialState);

  const fetchArticlesHandler = () => {
    fetchAllArticles({ page: 1, limit: PAGE_LIMIT });
    fetchMostViewedArticlesAll();
    fetchMostViewedArticlesToday();
    fetchMostViewedArticlesWeek();
    fetchMostViewedArticlesMonth();
  };
  useEffect(() => {
    fetchArticlesHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllArticles = (data) => {
    dispatch({
      type: actionTypes.ARTICLES_LOADING,
      payload: true,
    });
    axios
      .post("/article/all/", data, getHeaders())
      .then((res) => {
        dispatch({
          type: actionTypes.ARTICLES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        failure();
        dispatch({
          type: actionTypes.ARTICLES_FAILURE,
          payload: err?.response?.data?.message
            ? err.response.data.message
            : "",
        });
      });
  };

  const fetchMostViewedArticlesMonth = () => {
    dispatch({
      type: actionTypes.MOST_VIEWED_ARTICLES_MONTH_LOADING,
      payload: true,
    });
    axios
      .post("/article/most-viewed/month")
      .then((res) => {
        dispatch({
          type: actionTypes.MOST_VIEWED_ARTICLES_MONTH_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((err) => {
        failure();
        dispatch({
          type: actionTypes.MOST_VIEWED_ARTICLES_MONTH_FAILURE,
          payload: err?.response?.data?.message
            ? err.response.data.message
            : "",
        });
      });
  };

  const fetchMostViewedArticlesWeek = () => {
    dispatch({
      type: actionTypes.MOST_VIEWED_ARTICLES_WEEK_LOADING,
      payload: true,
    });
    axios
      .post("/article/most-viewed/week")
      .then((res) => {
        dispatch({
          type: actionTypes.MOST_VIEWED_ARTICLES_WEEK_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((err) => {
        failure();
        dispatch({
          type: actionTypes.MOST_VIEWED_ARTICLES_WEEK_FAILURE,
          payload: err?.response?.data?.message
            ? err.response.data.message
            : "",
        });
      });
  };

  const fetchMostViewedArticlesToday = () => {
    dispatch({
      type: actionTypes.MOST_VIEWED_ARTICLES_TODAY_LOADING,
      payload: true,
    });
    axios
      .post("/article/most-viewed/today")
      .then((res) => {
        dispatch({
          type: actionTypes.MOST_VIEWED_ARTICLES_TODAY_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((err) => {
        failure();
        dispatch({
          type: actionTypes.MOST_VIEWED_ARTICLES_TODAY_FAILURE,
          payload: err?.response?.data?.message
            ? err.response.data.message
            : "",
        });
      });
  };

  const fetchMostViewedArticlesAll = () => {
    dispatch({
      type: actionTypes.MOST_VIEWED_ARTICLES_LOADING,
      payload: true,
    });
    axios
      .post("/article/most-viewed/all")
      .then((res) => {
        dispatch({
          type: actionTypes.MOST_VIEWED_ARTICLES_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((err) => {
        failure();
        dispatch({
          type: actionTypes.MOST_VIEWED_ARTICLES_FAILURE,
          payload: err?.response?.data?.message
            ? err.response.data.message
            : "",
        });
      });
  };

  const fetchOneArticle = (id) => {
    dispatch({
      type: actionTypes.ONE_ARTICLE_LOADING,
      payload: true,
    });
    axios
      .get(`/article/all/${id}/`)
      .then((res) => {
        dispatch({
          type: actionTypes.ONE_ARTICLE_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((err) => {
        failure();
        dispatch({
          type: actionTypes.ONE_ARTICLE_FAILURE,
          payload: err?.response?.data?.message
            ? err.response.data.message
            : "",
        });
      });
  };

  const addArticle = (newArticle, cb) => {
    dispatch({
      type: actionTypes.ADD_ARTICLE_LOADING,
      payload: true,
    });
    axios
      .post("/article/add/", newArticle, getHeaders())
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_ARTICLE_LOADING,
          payload: false,
        });
        success(res.data.message);
        // fetchArticlesHandler();
        cb();
      })
      .catch((err) => {
        failure(err?.response?.data?.message);
        dispatch({
          type: actionTypes.ADD_ARTICLE_FAILURE,
          payload: err?.response?.data?.errors ? err.response.data.errors : {},
        });
      });
  };

  const incrementView = (id) => {
    axios
      .patch(`/article/increment-views/${id}/`)
      .catch((err) => failure(err?.response?.data?.message));
  };

  // Value object to be provided to consuming components
  const contextValue = {
    state,
    addArticle,
    fetchArticlesHandler,
    fetchAllArticles,
    fetchOneArticle,
    incrementView,
  };

  return (
    <ArticleContext.Provider value={contextValue}>
      {children}
    </ArticleContext.Provider>
  );
};

export const useArticle = () => useContext(ArticleContext);
