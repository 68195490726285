import { ADMIN_DASHBOARD, ADMIN_REGISTER } from "../../../Routes/urls";

export const adminNavLinks = [
  { name: "Dashboard", path: ADMIN_DASHBOARD },
  {
    name: "Manage",
    subLinks: [{ name: "Admin Registration", path: ADMIN_REGISTER }],
  },
  {
    name: "Services",
    subLinks: [
      { name: "Web Development", path: "/services/web" },
      { name: "App Development", path: "/services/app" },
      { name: "SEO Optimization", path: "/services/seo" },
    ],
  },
];
