import React, { Suspense, useEffect } from "react";
import LayoutContainer from "../components/Layout/LayoutContainer";
import FullScreenLoading from "../components/Loader/FullScreenLoading";
import { useNavigate } from "react-router-dom";
import { ADMIN_LOGIN, LOGIN } from "./urls";
import { getToken } from "../utils";

export default function PrivateRoute({ children, admin = false }) {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();
    if (!token) {
      if (admin) {
        navigate(ADMIN_LOGIN);
      } else navigate(LOGIN);
    }
  }, [admin, navigate]);

  return (
    <LayoutContainer>
      <Suspense fallback={<FullScreenLoading />}>{children}</Suspense>
    </LayoutContainer>
  );
}
