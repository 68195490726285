import React from "react";
import classes from "./styles.module.scss";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { isUserAdmin } from "../../utils";
import AdminNavBar from "./AdminNavBar/AdminNavBar";

export default function LayoutContainer({ children }) {
  const isAdmin = isUserAdmin();

  return (
    <div className={classes.LayoutContainer}>
      {isAdmin ? <AdminNavBar /> : <Navbar />}
      {children}
      <Footer />
    </div>
  );
}
