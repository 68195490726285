import React, { createContext, useContext, useReducer } from "react";
import axios from "../utils/axios";
import { failure, success } from "../utils/toast";
import { getHeaders } from "../utils";

// Initial state
const initialState = {
  loading: false,
  jobs: [],
  errors: {},
  addJob: {
    loading: false,
    errors: {},
  },
  oneJob: {
    loading: false,
    data: {},
    errors: "",
  },
};

// Action types for jobs
const actionTypes = {
  ADD_JOB_LOADING: "ADD_JOB_LOADING",
  ADD_JOB_FAILURE: "ADD_JOB_FAILURE",
  JOBS_LOADING: "JOBS_LOADING",
  JOBS_SUCCESS: "JOBS_SUCCESS",
  JOBS_FAILURE: "JOBS_FAILURE",
  ONE_JOB_LOADING: "ONE_JOB_LOADING",
  ONE_JOB_SUCCESS: "ONE_JOB_SUCCESS",
  ONE_JOB_FAILURE: "ONE_JOB_FAILURE",
};

// Reducer function to handle job actions
const jobReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_JOB_LOADING:
      return {
        ...state,
        addJob: {
          loading: action.payload,
        },
      };
    case actionTypes.ADD_JOB_FAILURE:
      return {
        ...state,
        addJob: {
          loading: false,
          errors: action.payload,
        },
      };
    case actionTypes.JOBS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        jobs: action.payload,
        errors: "",
      };
    case actionTypes.JOBS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case actionTypes.ONE_JOB_LOADING:
      return {
        ...state,
        oneJob: { loading: action.payload },
      };
    case actionTypes.ONE_JOB_SUCCESS:
      return {
        ...state,
        oneJob: { loading: false, data: action.payload, errors: "" },
      };
    case actionTypes.ONE_JOB_FAILURE:
      return {
        ...state,
        oneJob: { loading: false, errors: action.payload },
      };
    default:
      return state;
  }
};

// Create context
export const JobContext = createContext();

// Create context provider
export const JobProvider = ({ children }) => {
  const [state, dispatch] = useReducer(jobReducer, initialState);

  // Function to fetch all job listings
  const fetchJobs = () => {
    dispatch({
      type: actionTypes.JOBS_LOADING,
      payload: true,
    });

    axios
      .get("/jobs")
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: actionTypes.JOBS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        failure("Failed to fetch job listings");
        dispatch({
          type: actionTypes.JOBS_FAILURE,
          payload: error?.response?.data?.message || "Internal server error",
        });
      });
  };

  // Function to fetch a single job listing by ID
  const fetchOneJob = (jobId) => {
    dispatch({
      type: actionTypes.ONE_JOB_LOADING,
      payload: true,
    });

    axios
      .get(`/jobs/${jobId}`)
      .then((res) => {
        dispatch({
          type: actionTypes.ONE_JOB_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        failure("Failed to fetch job details");
        dispatch({
          type: actionTypes.ONE_JOB_FAILURE,
          payload: error?.response?.data?.message || "Internal server error",
        });
      });
  };

  const addJob = (newJob, cb) => {
    dispatch({
      type: actionTypes.ADD_JOB_LOADING,
      payload: true,
    });
    // Implement your API call to add a new job here
    axios
      .post("/jobs/", newJob, getHeaders())
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_JOB_LOADING,
          payload: false,
        });
        success(res.data.message);
        cb();
      })
      .catch((err) => {
        failure(err?.response?.data?.message);
        dispatch({
          type: actionTypes.ADD_JOB_FAILURE,
          payload: err?.response?.data?.errors ? err.response.data.errors : {},
        });
      });
  };

  // Value object to be provided to consuming components
  const contextValue = {
    ...state,
    fetchJobs,
    fetchOneJob,
    addJob,
  };

  return (
    <JobContext.Provider value={contextValue}>{children}</JobContext.Provider>
  );
};

export const useJobContext = () => useContext(JobContext);
