import React, { useState } from "react";
import styles from "./styles.module.scss";
import { adminNavLinks } from "./constants";
import { Link, useNavigate } from "react-router-dom";
import { HOME, LOGIN } from "../../../Routes/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChevronDown,
  faPaw,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../context/AuthProvider";

const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const auth = useAuth();
  const navigate = useNavigate();

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <nav className={styles.navbar}>
      <Link to={HOME} className={styles.navbar__logo}>
        <FontAwesomeIcon size="sm" icon={faPaw} style={{ color: "#fa8d30" }} />
        PetKahu
      </Link>

      {/* Mobile Menu Button */}
      <button
        className={styles.menuBtn}
        onClick={() => setMobileMenu(!mobileMenu)}
      >
        ☰
      </button>

      <ul className={`${styles.navList} ${mobileMenu ? styles.open : ""}`}>
        {adminNavLinks.map((link, index) => (
          <li key={index} className={styles.navItem}>
            {link.subLinks ? (
              <div
                className={`${styles.dropdown} ${styles.navLink} ${
                  index === 0 && styles.activeLink
                }`}
                onClick={() => toggleDropdown(index)}
              >
                {link.name}
                {link.subLinks?.length && (
                  <FontAwesomeIcon size="xs" icon={faChevronDown} />
                )}
                <ul
                  className={`${styles.dropdownMenu} ${
                    openDropdown === index ? styles.showDropdown : ""
                  }`}
                >
                  {link.subLinks.map((subLink, idx) => (
                    <li key={idx}>
                      <a href={subLink.path} className={styles.dropdownLink}>
                        {subLink.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <a
                href={link.path}
                className={`${styles.navLink} ${
                  index === 0 && styles.activeLink
                }`}
              >
                {link.name}
              </a>
            )}
          </li>
        ))}

        <button
          className={styles.logout}
          style={{ color: "red" }}
          title="Logout"
          onClick={() => {
            auth.logout(() => {
              navigate(LOGIN);
            });
          }}
        >
          <FontAwesomeIcon
            style={{ color: "red" }}
            icon={faArrowRightFromBracket}
          />
        </button>
      </ul>
    </nav>
  );
};

export default Navbar;
