import React from "react";
import classes from "./styles.module.scss";
import { Link } from "react-router-dom";
import {
  ADD_ARTICLE,
  ADD_JOBS,
  ADD_SERVICES,
  ENTERTAINMENT,
  HOME,
  LOGIN,
  MANAGE_ARTICLES,
  REGISTER,
  SEARCH_JOBS,
  SEARCH_SERVICES,
} from "../../../Routes/urls";

export default function Options({ active, name }) {
  return (
    <div
      className={`${classes.Navbar__links__options} ${
        active && classes.Navbar__links__options__active
      }`}
    >
      {name === "Blogs" && (
        <div className={classes.Navbar__links__options__container}>
          <h4>Blogs</h4>
          <Link to={HOME}>Home</Link>
          <Link to={ADD_ARTICLE}>Add Article</Link>
          <Link to={MANAGE_ARTICLES}>Manage Articles</Link>
        </div>
      )}
      {name === "Services" && (
        <div className={classes.Navbar__links__options__container}>
          <h4>Services</h4>
          <Link to={SEARCH_SERVICES}>Search Services</Link>
          <Link to={ADD_SERVICES}>Add Services</Link>
        </div>
      )}
      {name === "Jobs" && (
        <div className={classes.Navbar__links__options__container}>
          <h4>Jobs</h4>
          <Link to={SEARCH_JOBS}>Search Jobs</Link>
          <Link to={ADD_JOBS}>Add Jobs</Link>
        </div>
      )}
      {name === "Entertainment" && (
        <div className={classes.Navbar__links__options__container}>
          <h4>Entertainment</h4>
          <Link to={ENTERTAINMENT}>See All</Link>
        </div>
      )}

      <div className={classes.Navbar__links__options__container}>
        <h4>Links</h4>
        <Link to={REGISTER}>Register</Link>
        <Link to={LOGIN}>Login</Link>
      </div>
    </div>
  );
}
