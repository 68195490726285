export const PAGE_LIMIT = 3;

export const userTypesOptions = [
  { value: "Pet Professional", label: "Pet Professional" },
  { value: "Firm User", label: "Firm User" },
];

export const animalTypeOptions = [
  { value: "Cat", label: "Cat" },
  { value: "Dog", label: "Dog" },
  { value: "Rabbit", label: "Rabbit" },
  { value: "Hamster", label: "Hamster" },
  { value: "Exotic", label: "Exotic" },
];

export const serviceTypes = [
  { value: "Vet", label: "Vet" },
  { value: "Grooming", label: "Grooming" },
  { value: "Dog Walking", label: "Dog Walking" },
  { value: "Sitting", label: "Sitting" },
];

export const serviceTypeOptions = {
  Dog: [
    { value: "Vet", label: "Vet" },
    { value: "Grooming", label: "Grooming" },
    { value: "Dog Walking", label: "Dog Walking" },
    { value: "Sitting", label: "Sitting" },
  ],
  Cat: [
    { value: "Vet", label: "Vet" },
    { value: "Grooming", label: "Grooming" },
    { value: "Sitting", label: "Sitting" },
  ],
  Rabbit: [
    { value: "Vet", label: "Vet" },
    { value: "Sitting", label: "Sitting" },
  ],
  Hamster: [
    { value: "Vet", label: "Vet" },
    { value: "Sitting", label: "Sitting" },
  ],
  Exotic: [
    { value: "Vet", label: "Vet" },
    { value: "Sitting", label: "Sitting" },
  ],
};

export const contractTypes = [
  { value: "Contract", label: "Contract" },
  { value: "Fixed Term", label: "Fixed Term" },
  { value: "Permanent", label: "Permanent" },
  { value: "Temporary", label: "Temporary" },
];

export const hourTypes = [
  { value: "Part Time", label: "Part Time" },
  { value: "Full Time", label: "Full Time" },
];
